// automatic written by ruby script .. please dont edit these comments!
// storyblok attributes:
//   video_url -> text / Video-URL
//   caption -> textarea / Bildbeschreibung (optional)
// end of automatic

import React from "react"
import BlockWrapper from "../technical/BlockWrapper"

import VideoFigure from "../molecules/VideoFigure"
import HeadlineEnhanced from "../molecules/HeadlineEnhanced"

const VideoBlock = ({ block }) => {
  return (
    <>
      {block.video_url?.length > 0 && (
        <BlockWrapper block={block} showHeadline="false">
          <HeadlineEnhanced
            kicker={block.kicker}
            headline={block.headline}
            headlineLevel={block.headline_level}
            subheadline={block.subheadline}
            className={`${
              block.block_width === "screen"
                ? "w-full max-w-screen-xl mx-auto px-8"
                : ""
            }`}
          />
          <VideoFigure
            className={` ${block.headline?.length > 0 ? "pt-8" : ""}`}
            video_url={block.video_url}
            ratio={block.ratio}
            _uid={block._uid}
            caption={block.caption}
            captionClass={block.block_width === "screen" ? "px-8 lg:px-0" : ""}
          />
        </BlockWrapper>
      )}
    </>
  )
}

export default VideoBlock
